/* tslint:disable */

require('whatwg-fetch');

// old IE versions
require('intl');
require('blob-polyfill');

// base
require('@babel/polyfill');
require('formdata-polyfill');

require('regenerator-runtime/runtime');
require('core-js');
require('core-js/web/dom-collections');
require('core-js/es6/weak-map');
require('core-js/es6/map');
require('core-js/es6/string');
require('core-js/es6/array');
require('core-js/es6/object');
require('core-js/es6/promise');
